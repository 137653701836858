var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-module", {
    staticClass: "d-flex flex-grow-1 pt-6",
    attrs: { title: _vm.title, "has-elevation": true, "has-indicator": true },
    scopedSlots: _vm._u([
      {
        key: "moduleBody",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("v-textarea", {
                  attrs: {
                    outlined: "",
                    label: "Signature",
                    value: _vm.message,
                  },
                  model: {
                    value: _vm.message,
                    callback: function ($$v) {
                      _vm.message = $$v
                    },
                    expression: "message",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("mew-button", {
                      staticClass: "mr-4",
                      attrs: { "btn-style": "light", title: "Clear all" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.clearAll.apply(null, arguments)
                        },
                      },
                    }),
                    _c("mew-button", {
                      attrs: { title: "Sign", disabled: _vm.disableSignBtn },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.signMessage.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }